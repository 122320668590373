(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  "mobile": {
    "landscape": 896,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1024,
    "portrait": 768
  },
  "notebook": {
    "small": 1280,
    "normal": 1500
  }
};

},{}],2:[function(require,module,exports){
'use strict';

require('./modules/lazyload.js');

require('./modules/modals.js');

require('./modules/forms.js');

require('./modules/masks.js');

require('./modules/spinner.js');

require('./modules/scroll.js');

require('./modules/menus.js');

require('./modules/tabs.js');

require('./modules/sliders.js');

require('./modules/spoilers.js');

require('./modules/history.js');

require('./modules/video.js');

require('./modules/homepage.js');

// import './modules/animation.js';
function progressBar() {
  var scroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  var scrolled = scroll / height * 100;

  document.getElementById('progressBar').style.width = scrolled + '%';
}

window.addEventListener('scroll', progressBar);

// import './modules/vuejs/card/card-app.js';
// import './modules/vuejs/order/order-app.js';

// import InstagramWidget from './modules/widgets/instagram.js';
// import ClosableTeaser from './modules/widgets/closable-teaser.js';


// Init instagram widget
// ---------------------

// const instagram = new InstagramWidget({
//   limit: {
//     default: 12,
//     mobile: 4,
//   },
// });


// Init closable teaser
// --------------------

// const teaser = new ClosableTeaser({
//   selector: '.js-floating-teaser',
//   closeSelector: '.js-close-floating-teaser',
//   storageKey: 'TEASER_HIDE_DATE',
//   daysToHide: 7,
// });

},{"./modules/forms.js":3,"./modules/history.js":6,"./modules/homepage.js":7,"./modules/lazyload.js":8,"./modules/masks.js":9,"./modules/menus.js":10,"./modules/modals.js":11,"./modules/scroll.js":12,"./modules/sliders.js":13,"./modules/spinner.js":14,"./modules/spoilers.js":15,"./modules/tabs.js":16,"./modules/video.js":17}],3:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

// Selectric
// ---------

_globals.$DOCUMENT.on('initSelectric yiiListViewUpdated', function () {
  $('select:not(.no-default-selectric)').selectric({
    disableOnMobile: false,
    nativeOnMobile: true
  });
}).trigger('initSelectric');

// Checkboxes
// ----------

/* global autosize */

_globals.$BODY.on('change initCheckboxes', '.js-checkbox input', function (event) {
  var $inp = $(event.target);
  var $label = $inp.closest('.js-checkbox');

  if ($inp.prop('checked')) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }
});
$('.js-checkbox input').trigger('initCheckboxes');

// Radio buttons
// -------------

_globals.$BODY.on('change initRadio', '.js-radio input', function (event) {
  var $inp = $(event.target);
  var $group = $('[name="' + $inp.attr('name') + '"]');
  var $labels = $group.closest('.js-radio');
  var $selectedItem = $labels.find('input').filter(':checked').closest('.js-radio');

  $labels.removeClass('is-checked');
  $selectedItem.addClass('is-checked');
});
$('.js-radio input').trigger('initRadio');

// Textarea autosize
// -----------------

$(function () {
  autosize($('textarea'));
});

// Touched inputs state
// --------------------

$('.form__input, .form__textarea').on('focus', function focusHandler() {
  $(this).closest('.form__field').addClass('is-touched');
}).on('blur', function blurHandler() {
  var $this = $(this);
  var value = $this.val();

  if (value !== '' && value != null && value !== '+7 (___) ___-__-__') return;

  $this.closest('.form__field').removeClass('is-touched');
}).on('inputs-init', function inputsInit() {
  var $this = $(this);
  var value = $this.val();

  if (value !== '' && value != null && value !== '+7 (___) ___-__-__') {
    $this.closest('.form__field').addClass('is-touched');
  }
}).trigger('inputs-init');

// Fileinputs
// ----------

_globals.$BODY.on('change', '.js-fileinput input', function changeHandler(event) {
  $(event.target).closest('.js-fileinput').parent().find('.js-fileinput-filename').text($(this).val());
});

},{"./globals.js":4}],4:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.$BODY = exports.$HTML = exports.$DOCUMENT = exports.$WINDOW = exports.HEADER_HEIGHT = exports.NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = exports.TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = exports.MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = exports.IS_TOUCH_DEVICE = exports.IS_IE = exports.IS_IOS = exports.IS_DESKTOP = exports.IS_MOBILE = undefined;

var _mediaQueriesConfigJson = require('../../media-queries-config.json.js');

var _mediaQueriesConfigJson2 = _interopRequireDefault(_mediaQueriesConfigJson);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Const
// -----

var IS_MOBILE = exports.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
var IS_DESKTOP = exports.IS_DESKTOP = !IS_MOBILE;
var IS_IOS = exports.IS_IOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g);
var IS_IE = exports.IS_IE = navigator.appVersion.indexOf('MSIE') !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
var IS_TOUCH_DEVICE = exports.IS_TOUCH_DEVICE = 'ontouchstart' in document;

var HTML = document.documentElement;

HTML.classList.remove('no-js');

if (IS_MOBILE) HTML.classList.add('is-mobile');
if (IS_DESKTOP) HTML.classList.add('is-desktop');
if (IS_IOS) HTML.classList.add('is-ios');
if (IS_IE) HTML.classList.add('is-ie');
if (IS_TOUCH_DEVICE) HTML.classList.add('is-touch-device');

var SMALL_MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.small;
var MOBILE_WIDTH = exports.MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.portrait;
var LANDSCAPE_MOBILE_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.landscape;
var PORTRAIT_TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.portrait;
var TABLET_WIDTH = exports.TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.landscape;
var SMALL_NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.small;
var NOTEBOOK_WIDTH = exports.NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.normal;

var HEADER_HEIGHT = exports.HEADER_HEIGHT = $('.header').height();

// Selectors
// ---------

var $WINDOW = exports.$WINDOW = $(window);
var $DOCUMENT = exports.$DOCUMENT = $(document);
var $HTML = exports.$HTML = $(document.documentElement);
var $BODY = exports.$BODY = $(document.body);

// Custom vh for mobile devices
// ----------------------------
// css example:
// height: calc(var(--vh, 1vh) * 100);

$(window).on('resize initVh', function () {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
}).trigger('initVh');

// Trigger fonts loaded event
// --------------------------

if (document.fonts !== undefined) {
  document.fonts.onloadingdone = function fontsLoadedCallback() {
    $(document).trigger('fontsLoaded');
    $(window).trigger('fontsLoaded');
  };
}

},{"../../media-queries-config.json.js":1}],5:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_SMALL_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = exports.IS_TABLET_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = exports.IS_DESKTOP_WIDTH = exports.WINDOW_HEIGHT = exports.WINDOW_WIDTH = undefined;
exports.status = status;

var _globals = require('./globals.js');

/* eslint-disable import/no-mutable-exports */
var WINDOW_WIDTH = exports.WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
var WINDOW_HEIGHT = exports.WINDOW_HEIGHT = _globals.$WINDOW.height();
/* eslint-enable import/no-mutable-exports */
_globals.$WINDOW.resize(function () {
  exports.WINDOW_WIDTH = WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
  exports.WINDOW_HEIGHT = WINDOW_HEIGHT = _globals.$WINDOW.height();
});

var IS_DESKTOP_WIDTH = exports.IS_DESKTOP_WIDTH = function IS_DESKTOP_WIDTH() {
  return WINDOW_WIDTH > _globals.NOTEBOOK_WIDTH;
};
var IS_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = function IS_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= _globals.NOTEBOOK_WIDTH;
};
var IS_SMALL_NOTEBOOK_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = function IS_SMALL_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH && WINDOW_WIDTH <= _globals.SMALL_NOTEBOOK_WIDTH;
};
var IS_TABLET_WIDTH = exports.IS_TABLET_WIDTH = function IS_TABLET_WIDTH() {
  return WINDOW_WIDTH >= _globals.PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = function IS_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.MOBILE_WIDTH;
};
var IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = function IS_LANDSCAPE_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.LANDSCAPE_MOBILE_WIDTH;
};
var IS_SMALL_MOBILE_WIDTH = exports.IS_SMALL_MOBILE_WIDTH = function IS_SMALL_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.SMALL_MOBILE_WIDTH;
};

// Response status
function status(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

},{"./globals.js":4}],6:[function(require,module,exports){
'use strict';

var $backLink = $('.js-history-back');
var currentUrl = window.location.href;

if ($backLink.length) {
  if (document.referrer === '') {
    $backLink.css('visibility', 'hidden');
  } else {
    $backLink.click(function () {
      window.history.back();
      setTimeout(function () {
        // if location was not changed in 100 ms, then there is no history back
        if (currentUrl === window.location.href) {
          window.close();
        }
      }, 100);
    });
  }
}

},{}],7:[function(require,module,exports){
'use strict';

/* eslint-disable */

(function () {
  'use strict';

  /* Lazy Load */
  // const lazyLoadInstance = new LazyLoad();

  /* Fancybox */
  // Fancybox.bind("[data-fancybox]", {});

  /* History Swiper */

  var swiper_history = document.querySelector('[data-swiper-history]');
  if (swiper_history) {
    new Swiper(swiper_history, {
      slidesPerView: 5,
      spaceBetween: '4%',
      centeredSlides: true,
      loop: true,
      loopedSlides: 2,
      speed: 1000,
      navigation: {
        nextEl: swiper_history.querySelector('[data-swiper-next]'),
        prevEl: swiper_history.querySelector('[data-swiper-prev]')
      },
      pagination: {
        el: swiper_history.querySelector('[data-swiper-progress]'),
        type: 'progressbar'
      },
      breakpoints: {
        0: {
          slidesPerView: 2.3,
          spaceBetween: '20',
          centeredSlides: false
        },
        575: {
          slidesPerView: 3.3,
          spaceBetween: '20',
          centeredSlides: false
        },
        767: {
          slidesPerView: 5,
          spaceBetween: '4%',
          centeredSlides: true
        }
      },
      on: {
        init: function init() {
          var swiper_title_target = swiper_history.querySelector('[data-swiper-title-target]'),
              swiper_title = swiper_history.querySelector('.swiper-slide-active [data-swiper-title]').innerHTML;
          if (swiper_title_target) {
            swiper_title_target.innerHTML = swiper_title;
          }
        },
        realIndexChange: function realIndexChange(swiper) {
          var swiper_title_target = swiper_history.querySelector('[data-swiper-title-target]'),
              swiper_title = swiper_history.querySelector('[data-swiper-slide-index="' + swiper.realIndex + '"] [data-swiper-title]').innerHTML;
          if (swiper_title_target) {
            swiper_title_target.innerHTML = swiper_title;
          }
        }
      }
    });
  }

  /* Mission Swiper */
  var swiper_mission = document.querySelector('[data-swiper-mission]');
  if (swiper_mission) {
    new Swiper(swiper_mission, {
      slidesPerView: 4,
      spaceBetween: '5%',
      // speed: 1000,
      loop: true,
      navigation: {
        nextEl: document.querySelector('[data-swiper-mission-next]'),
        prevEl: document.querySelector('[data-swiper-mission-prev]')
      },
      pagination: {
        el: swiper_mission.querySelector('[data-swiper-progress]'),
        type: 'progressbar'
      },
      breakpoints: {
        0: {
          slidesPerView: 1.2,
          spaceBetween: 50
        },
        575: {
          slidesPerView: 2.3,
          spaceBetween: 50
        },
        767: {
          slidesPerView: 3,
          spaceBetween: '3%'
        },
        1199: {
          slidesPerView: 4,
          spaceBetween: '5%'
        }
      }
    });
  }

  /* Work Swiper */
  var swiper_work = document.querySelector('[data-swiper-work]');
  if (swiper_work) {
    new Swiper(swiper_work, {
      slidesPerView: 4,
      spaceBetween: 12,
      // speed: 1000,
      pagination: {
        el: swiper_work.querySelector('[data-swiper-progress]'),
        type: 'progressbar'
      },
      breakpoints: {
        0: {
          slidesPerView: 1.1,
          spaceBetween: 20
        },
        575: {
          slidesPerView: 2.3,
          spaceBetween: 20
        },
        767: {
          slidesPerView: 4,
          spaceBetween: 12
        }
      }
    });
  }

  /* Press Swiper */
  var swiper_press = document.querySelector('[data-swiper-press]');
  if (swiper_press) {
    new Swiper(swiper_press, {
      slidesPerView: 3,
      spaceBetween: 40,
      // speed: 1000,
      pagination: {
        el: swiper_press.querySelector('[data-swiper-progress]'),
        type: 'progressbar'
      },
      breakpoints: {
        0: {
          slidesPerView: 1.1,
          spaceBetween: 20
        },
        575: {
          slidesPerView: 2.3,
          spaceBetween: 20
        },
        870: {
          slidesPerView: 3,
          spaceBetween: 20
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      }
    });
  }

  /* Use Swiper */
  var swiper_use = document.querySelector('[data-swiper-use]');
  if (swiper_use) {
    new Swiper(swiper_use, {
      slidesPerView: 3,
      spaceBetween: '4%',
      // speed: 1000,
      pagination: {
        el: swiper_use.querySelector('[data-swiper-progress]'),
        type: 'progressbar'
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: '10%'
        },
        575: {
          slidesPerView: 2,
          spaceBetween: '10%'
        },
        767: {
          slidesPerView: 3,
          spaceBetween: '4%'
        }
      }
    });
  }

  /* Mask Phone */
  window.addEventListener("DOMContentLoaded", function () {
    [].forEach.call(document.querySelectorAll('[data-tel]'), function (input) {
      var keyCode = void 0;

      function mask(event) {
        event.keyCode && (keyCode = event.keyCode);
        var pos = this.selectionStart;
        if (pos < 3) event.preventDefault();
        var matrix = "+7 (___) ___-__-__",
            i = 0,
            def = matrix.replace(/\D/g, ""),
            val = this.value.replace(/\D/g, ""),
            new_value = matrix.replace(/[_\d]/g, function (a) {
          return i < val.length ? val.charAt(i++) || def.charAt(i) : a;
        });
        i = new_value.indexOf("_");
        if (i !== -1) {
          i < 5 && (i = 3);
          new_value = new_value.slice(0, i);
        }
        var reg = matrix.substr(0, this.value.length).replace(/_+/g, function (a) {
          return "\\d{1," + a.length + "}";
        }).replace(/[+()]/g, "\\$&");
        reg = new RegExp("^" + reg + "$");
        if (!reg.test(this.value) || this.value.length < 5 || keyCode > 47 && keyCode < 58) this.value = new_value;
        if (event.type === "blur" && this.value.length < 5) this.value = "";
      }

      input.addEventListener("input", mask, false);
      input.addEventListener("focus", mask, false);
      input.addEventListener("blur", mask, false);
      input.addEventListener("keydown", mask, false);
    });
  });
})();
/* eslint-enable */

},{}],8:[function(require,module,exports){
'use strict';

(function () {

  var lazyloadImages = document.querySelectorAll('.js-lazy-pic');
  var imageIntersectionObserver = void 0;
  var completed = false;
  var lazyloadThrottleTimeout = void 0;

  document.addEventListener('DOMContentLoaded', function () {
    initLazyLoad();
    addImagesListUpdateHandlers();
  });

  function initLazyLoad() {
    if (supportsIntersectionObserver()) return observeImages();
    return observerFallback();
  }

  function supportsIntersectionObserver() {
    return 'IntersectionObserver' in window;
  }

  function observeImages() {
    createObserver();
    lazyloadImages.forEach(function (image) {
      imageIntersectionObserver.observe(image);
    });
  }

  function createObserver() {
    imageIntersectionObserver = new IntersectionObserver(function (entries) {
      processImages(entries);
    }, {
      rootMargin: '200px'
    });
  }

  function processImages(images) {
    images.forEach(function (item) {
      if (item.isIntersecting) {
        changeSrc(item.target);
        imageIntersectionObserver.unobserve(item.target);
      }
    });
  }

  function changeSrc(image) {
    if (image.tagName === 'IMG') {
      image.src = image.dataset.src;
    } else if (image.tagName === 'SOURCE') {
      image.srcset = image.dataset.srcset;
    }
    image.classList.remove('js-lazy-pic');
  }

  function observerFallback() {
    document.addEventListener('scroll', lazyload);
    window.addEventListener('resize', lazyload);
    window.addEventListener('orientationChange', lazyload);
  }

  function lazyload() {
    lazyloadImages = document.querySelectorAll('.js-lazy-pic');

    if (lazyloadThrottleTimeout) {
      clearTimeout(lazyloadThrottleTimeout);
    }

    lazyloadThrottleTimeout = setTimeout(function () {
      lazyloadImages.forEach(function (image) {
        if (imageIsReached(image)) {
          changeSrc(image);
        }
      });
      if (noMoreImagesLeft()) {
        removeLazyLoadListeners();
        completed = true;
      }
    }, 20);
  }

  function imageIsReached(image) {
    var scrollTop = window.pageYOffset;
    return image.offsetTop < window.innerHeight + scrollTop;
  }

  function noMoreImagesLeft() {
    return lazyloadImages.length === 0;
  }

  function addImagesListUpdateHandlers() {
    $(document).on('yiiListViewUpdated', function () {
      addImagesToObserver();
    });
    $('body').on('iasRender', function () {
      addImagesToObserver();
    });
  }

  function addImagesToObserver() {
    lazyloadImages = document.querySelectorAll('.js-lazy-pic');
    if (supportsIntersectionObserver()) {
      lazyloadImages.forEach(function (image) {
        imageIntersectionObserver.observe(image);
      });
    } else if (completed) {
      observerFallback();
    }
  }

  function removeLazyLoadListeners() {
    document.removeEventListener('scroll', lazyload);
    window.removeEventListener('resize', lazyload);
    window.removeEventListener('orientationChange', lazyload);
  }
})();

},{}],9:[function(require,module,exports){
'use strict';

$('input[type="tel"]').each(function initMask() {

  var $input = $(this);
  var defaultPlaceholder = $input.attr('placeholder') || '';
  var defaultPlaceholderMask = '+7 (___) ___-__-__';
  var phoneMask = '+7 (000) 000-00-00';
  var pasted = false;

  $input.on('focus', function () {
    $input.attr('placeholder', defaultPlaceholderMask);
  }).on('blur', function () {
    $input.attr('placeholder', defaultPlaceholder);
  });

  $input.on('paste drop', function () {
    var isEmpty = !$input.val();
    var element = $input.get(0);
    var selectionLength = element.selectionEnd - element.selectionStart;

    if (!isEmpty && $input.val().length !== selectionLength) {
      return;
    }

    pasted = true;
  });

  $input.on('input', function (e) {
    var value = $input.val();

    if (value.startsWith('8')) {
      if (pasted && value.length !== 11) {
        pasted = false;
        return;
      }

      pasted = false;

      e.stopPropagation();

      value = value.replace('8', '+7');
      $input.val(value);
    }
  });

  $input.mask(phoneMask);
});

},{}],10:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var $menus = $('.js-menu');

if (!_globals.IS_TOUCH_DEVICE) {
  $menus.on('mouseenter.js-menu', 'li', function mouseenterHandler() {
    var $this = $(this);

    clearTimeout($this.data('unhoverTimeout'));

    if ($this.hasClass('has-dropdown')) {
      $this.data('hoverTimeout', setTimeout(function () {
        $this.addClass('is-hovered');
      }, 200));
    } else {
      $this.addClass('is-hovered');
    }
  });

  $menus.on('mouseleave.js-menu', 'li', function mouseleaveHandler() {
    var $this = $(this);

    clearTimeout($this.data('hoverTimeout'));

    $this.data('unhoverTimeout', setTimeout(function () {
      $this.removeClass('is-hovered');
    }, 200));
  });
}

if (_globals.IS_TOUCH_DEVICE) {
  $menus.on('click.js-m-menu', 'a', function clickHandler(e) {
    e.preventDefault();

    var $anchor = $(this);
    var $parent = $anchor.parent();
    var hasDropdown = $parent.hasClass('has-dropdown');
    var isHovered = $parent.hasClass('is-hovered');
    var link = $anchor.attr('href');

    $parent.siblings().removeClass('is-hovered');

    if (!hasDropdown && link) {
      window.location.href = $anchor.attr('href');
    } else if (isHovered && link) {
      window.location.href = link;
    } else {
      $parent.toggleClass('is-hovered');
    }
  });
}

(function () {

  // if (!$('.popup-menu').length) return;

  var openedClass = 'menu-is-opened';

  var url = new URL(window.location);
  // if (url.searchParams.get('menu') === 'opened') {
  //   $BODY.addClass(openedClass);
  // }

  $('.js-toggle-popup-menu').click(function () {

    _globals.$BODY.toggleClass(openedClass);

    $('.popup-menu .is-hovered').removeClass('is-hovered');

    if (_globals.$BODY.hasClass(openedClass)) {
      url = new URL(window.location);
      url.searchParams.set('menu', 'opened');
      window.history.pushState({}, '', url);

      _globals.$BODY.on('click.close-menu', function (ev) {
        var $target = $(ev.target);

        if ($target.closest('.popup-menu').length || $target.hasClass('js-toggle-popup-menu')) {
          return;
        }

        closeMenu(true);
      });
    } else {
      closeMenu(true);
    }
  });

  function closeMenu(back) {
    _globals.$BODY.removeClass(openedClass).off('click.close-menu');

    url = new URL(window.location);
    if (url.searchParams.get('menu') && back) {
      window.history.back();
    }
  }

  window.addEventListener('popstate', onPopstate);

  function onPopstate() {
    if (_globals.$BODY.hasClass(openedClass)) {
      closeMenu(false);
    }
  }

  $('.js-toggle-mobile-menu-dropdown').click(function togglerClickHandler() {
    $(this).closest('ul').toggleClass('is-expanded');
  });

  // const hammerobject = new Hammer($('.popup-menu')[0], {});
  // hammerobject.on('swipeleft', function(e) {
  //   if ($BODY.hasClass(openedClass)) {
  //     closeMenu(true);
  //   }
  // });
})();

},{"./globals.js":4}],11:[function(require,module,exports){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; /* global initCardApp */

var _globals = require('./globals.js');

var OVERLAY_LOADER_DEFAULTS = {
  hideSelector: '.js-close-popup',
  fixedElements: '.js-fixed-element',
  onShow: function onShow() {
    _globals.$DOCUMENT.trigger('popupShown');
  }
};

_globals.$DOCUMENT.on('click.overlay-open', '.js-overlay', function clickHandler(e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, _extends({
    node: $popup
  }, OVERLAY_LOADER_DEFAULTS));
});

_globals.$DOCUMENT.on('click.map-overlay-open', '.js-map-content-overlay', function clickHandler(e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, _extends({
    node: $popup
  }, OVERLAY_LOADER_DEFAULTS, {
    onShow: function onShow() {
      _globals.$DOCUMENT.trigger('initMapTabSliders');
    }
  }));
});

_globals.$DOCUMENT.on('click.overlay-open', '.js-ajax-overlay', function clickHandler(e) {
  e.preventDefault();

  var $self = $(this);
  var $node = $($self.attr('href'));

  $.overlayLoader(true, _extends({
    node: $node
  }, OVERLAY_LOADER_DEFAULTS));

  $.ajax({
    type: $self.data('type'),
    url: $self.data('ajax-url'),
    data: parseAjaxOverlayParams($self.data('ajax-params')),
    timeout: 10000,
    success: function success(data) {
      $node.find('.js-ajax-data').replaceWith(data);
      initCardApp('#' + $node.find('.js-card-app').attr('id'));
      setTimeout(function () {
        $(document).trigger('ajaxPopupLoaded');
      }, 350);
    },
    error: function error() {}
  });
});

function parseAjaxOverlayParams(str) {
  if (str === undefined || str === '') return {};

  if ((typeof str === 'undefined' ? 'undefined' : _typeof(str)) === 'object') return str;

  var jsonParams = JSON.parse(str);
  if (jsonParams) return jsonParams;

  var obj = {};
  var array = str.split('&');

  array.forEach(function (value) {
    var a = value.split('=');
    /* eslint-disable prefer-destructuring */
    obj[a[0]] = a[1];
    /* eslint-enable prefer-destructuring */
    return true;
  });

  return obj;
}

// Autofocus
_globals.$DOCUMENT.on('overlayLoaderShow', function (e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

// Coockie alert
_globals.$DOCUMENT.on('click', '.js-close-alert', function closeAlert(e) {
  e.preventDefault();
  var $parent = $(this).closest('.js-alert');

  $parent.removeClass('--shown');
  localStorage.setItem('shown', 'yes');
});

},{"./globals.js":4}],12:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

// Scroll to
// ---------

_globals.$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function clickHandler(e) {
  e.preventDefault();

  var $link = $(this);
  var $elementToScroll = $($link.attr('href'));
  var speed = $link.data('speed') || 150;
  var offset = $link.data('offset') || $('.header').length && (0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)() ? -$('.header').height() : 0;
  _globals.$WINDOW.scrollTo($elementToScroll, { duration: speed, offset: offset });
});

// Scrolling to top
// ----------------

if (!_helpers.IS_MOBILE_WIDTH) {
  var $goTopButton = $('<div class="go-top-btn"></div>');

  $goTopButton.click(function () {
    _globals.$WINDOW.scrollTo(0, 200);
  });

  _globals.$WINDOW.scroll(function () {
    var scrollTop = _globals.$WINDOW.scrollTop();
    if (scrollTop > 0) {
      $goTopButton.addClass('is-visible');
    } else {
      $goTopButton.removeClass('is-visible');
    }
  });

  _globals.$BODY.append($goTopButton);
}

// Inview
// ------

$(function () {
  $('.js-inview').each(function inviewHandler() {
    var _this = this;

    $(this).bind('inview', function (event, isInView, visiblePartX, visiblePartY) {
      // $(this).bind('inview', (event, isInView) => {
      if ($(_this).hasClass('js-inview-top') && isInView || (!(0, _helpers.IS_SMALL_MOBILE_WIDTH)() ? visiblePartY === 'top' || visiblePartY === 'center' : isInView)
      // isInView
      ) {
          $(_this).addClass('inview').unbind('inview');
          $(_this).trigger('inviewTriggered');
        }
      $(window).trigger('inviewTriggered');
    });
  });
});

},{"./globals.js":4,"./helpers.js":5}],13:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

$('.js-slideshow').each(function createSlider() {
  var $this = $(this);

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    arrows: true,
    pauseOnHover: false,
    touchThreshold: 50
    // adaptiveHeight: true,
  });
});

$('.js-help-slider').each(function createSlider() {
  if ($(window).width() <= 1024) {
    var $this = $(this);

    $this.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: false,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: false,
      arrows: true,
      pauseOnHover: false,
      touchThreshold: 50,
      responsive: [{
        breakpoint: _globals.TABLET_WIDTH + 1,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: _globals.MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  }
});

$('.js-vitrine-slider').each(function createSlider() {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    appendArrows: $parent,
    responsive: [{
      breakpoint: _globals.TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1
      }
    }]
  });
});

$('.js-gallery-slider').each(function createSlider() {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    appendArrows: $parent,
    responsive: [{
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 4
      }
    }]
  });
});

$('.js-ambassadors-slider').each(function createSlider() {
  if ($(window).width() <= 1024) {
    var $this = $(this);

    $this.slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: false,
      arrows: true,
      pauseOnHover: false,
      touchThreshold: 50,
      responsive: [{
        breakpoint: 700 + 1,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }, {
        breakpoint: 480 + 1,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }]
    });
  }
});

$('.js-blog-banners-slider').each(function createSlider() {
  var $this = $(this);
  var $controls = $this.closest('[data-slider-root]').find('.js-slider-controls');

  $this.slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    autoplay: false,
    dots: false,
    arrows: true,
    appendArrows: $controls,
    touchThreshold: 50,
    swipeToSlide: true,
    responsive: [{
      breakpoint: 700 + 1,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  });
});

(function () {
  $('.js-history-slider').each(function createSlider() {
    var $this = $(this);

    $this.slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 5000,
      dots: false,
      arrows: true,
      // appendArrows: $controls.find('.arrows'),
      pauseOnHover: true,
      touchThreshold: 50,
      swipeToSlide: true,
      responsive: [{
        breakpoint: 1600 + 1,
        settings: {
          slidesToShow: 4
        }
      }, {
        breakpoint: 1280 + 1,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 896 + 1,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 700 + 1,
        settings: {
          slidesToShow: 1
        }
      }]
    });
  });
})();

// $('.js-scroller-dots').each(function createScrollerDots() {
//   const $slider = $(this);
//   const $pager = $('<ul class="slick-dots"></ul>');
//   const $items = $slider.find('> *');
//   let itemsObserver;
//   let itemObserveThrottleTimeout;

//   if ($(window).width() > SMALL_MOBILE_WIDTH) {
//     $slider.next('.scroller-dots').remove();
//     return false;
//   }

//   if (!$slider.next('.scroller-dots').length) {
//     $slider.after($('<div class="scroller-dots"></div>').append($pager));

//     $items.each(function createDot() {
//       const $item = $(this);
//       const $dot = $('<li><button></button></li>');
//       $pager.append($dot);

//       $dot.click(() => {
//         $slider.scrollTo($item, 250);
//       });
//     });

//     if ('IntersectionObserver' in window) {
//       itemsObserver = new IntersectionObserver((entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             const index = $(entry.target).index();
//             $pager.find(`li:nth-child(${index + 1})`).addClass('is-active slick-active')
//               .siblings().removeClass('is-active slick-active');
//           }
//         });
//       });

//       $items.each(function createObserver() {
//         itemsObserver.observe(this);
//       });
//     } else {
//       $slider[0].addEventListener('scroll', changeDots);
//       $pager.find('li:first-child').addClass('is-active slick-active');
//       window.addEventListener('resize', changeDots);
//       window.addEventListener('orientationChange', changeDots);
//     }
//   }

//   function changeDots() {
//     if (itemObserveThrottleTimeout) {
//       clearTimeout(itemObserveThrottleTimeout);
//     }
//     itemObserveThrottleTimeout = setTimeout(() => {
//       let index;
//       const scrollLeft = $slider.scrollLeft();
//       $items.each((i, item) => {
//         if (scrollLeft < $(item)[0].offsetLeft) {
//           index = i;
//           return false;
//         }
//         return true;
//       });
//       $pager.find(`li:nth-child(${index + 1})`).addClass('is-active slick-active')
//         .siblings().removeClass('is-active slick-active');
//     }, 20);
//   }

//   return $pager;
// });

},{"./globals.js":4}],14:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

_globals.$DOCUMENT.on('mousedown.js-spinner', '.js-spinner-down, .js-spinner-up', function mousedownHandler() {
  var $spinnerControl = $(this);
  var $input = $spinnerControl.siblings('input');
  var step = $input.data('step') ? $input.data('step') : 1;
  var mayBeZero = $input.data('zero') ? $input.data('zero') : false;
  var value = parseInt($input.val(), 10);
  var incTimeout = void 0;
  var incInterval = void 0;
  var decTimeout = void 0;
  var decInterval = void 0;

  $spinnerControl.on('mouseup.js-spinner', clearAll).on('mouseleave.js-spinner', $spinnerControl, clearAll);

  if ($spinnerControl.hasClass('js-spinner-down')) {
    decVal();decTimeout = setTimeout(function () {
      decInterval = setInterval(decVal, 70);
    }, 300);
  }

  if ($spinnerControl.hasClass('js-spinner-up')) {
    incVal();incTimeout = setTimeout(function () {
      incInterval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ($.isMouseLoaderActive()) return;

    value += step;
    $input.val(value).change();
  }

  function decVal() {
    if ($.isMouseLoaderActive()) return;

    if (mayBeZero) {
      if (value >= step) {
        value -= step;
        $input.val(value).change();
      }
    } else if (value > step) {
      value -= step;
      $input.val(value).change();
    }
  }

  function clearAll() {
    clearTimeout(decTimeout);clearInterval(decInterval);
    clearTimeout(incTimeout);clearInterval(incInterval);
  }
});

_globals.$DOCUMENT.on('keydown', '.js-spinner input', function (e) {
  if (isKeyToSkip(e.keyCode, e.ctrlKey)) {
    return e.keyCode;
  }
  if (isNotADigitKey(e.keyCode)) {
    e.preventDefault();
  }
  return e.keyCode;
});

_globals.$DOCUMENT.on('keyup paste', '.js-spinner input', function keyupHandler() {
  var $input = $(this);
  var mayBeZero = $input.data('zero') ? $input.data('zero') : false;

  if ($input.val() === 0 || $input.val() === '') {
    if (!mayBeZero) {
      $input.val(1);
    } else {
      $input.val(0);
    }
  }
});

function isKeyToSkip(keyCode, ctrlKey) {
  return isDelKey(keyCode) || isBackspaceKey(keyCode) || isTabKey(keyCode) || isEscKey(keyCode) || isSelectAllHotkeys(keyCode, ctrlKey) || isPositioningKey(keyCode);
}

function isDelKey(keyCode) {
  return keyCode === 46;
}

function isBackspaceKey(keyCode) {
  return keyCode === 8;
}

function isTabKey(keyCode) {
  return keyCode === 9;
}

function isEscKey(keyCode) {
  return keyCode === 27;
}

function isSelectAllHotkeys(keyCode, ctrlKey) {
  // Ctrl+A
  return keyCode === 65 && ctrlKey === true;
}

function isPositioningKey(keyCode) {
  // arrows or home / end
  return keyCode >= 35 && keyCode <= 39;
}

function isNotADigitKey(keyCode) {
  // including numpad keys
  return (keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105);
}

},{"./globals.js":4}],15:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

$(function () {

  $('.js-spoiler').each(function spoilerHandler() {
    var $this = $(this);
    $(window).on('spoilersInit resize fontsLoaded', function () {
      var isExpanded = $this.hasClass('is-expanded');
      var isInsideHiddenTab = $this.closest('.tabs-panel:not(.is-active)').length;
      var isInsideCollapsedSpoiler = $this.closest('.js-spoiler:not(.is-expanded)').length;
      var isInsideFilter = $this.closest('#filter-aside').length;
      var $el = $this.find('.js-spoiler-body').first();
      var el = $el[0];
      $('.js-spoiler-body').each(function addTempState() {
        $(this)[0].style.setProperty('transition', 'none');
        $(this).find('ul').addClass('is-expanded-for-calc');
      });
      if (!isExpanded) $this.addClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').addClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').addClass('is-active');
      if (isInsideFilter) $this.closest('.catalogue-page__aside')[0].style.setProperty('display', 'block');
      el.style.setProperty('--max-height', '');
      el.style.setProperty('--max-height', $el.outerHeight() + 'px');
      if (!isExpanded) $this.removeClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').removeClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').removeClass('is-active');
      if (isInsideFilter) $this.closest('.catalogue-page__aside')[0].style.setProperty('display', '');
      $('.js-spoiler-body').each(function removeTempState() {
        $(this)[0].style.setProperty('transition', '');
        $(this).find('ul').removeClass('is-expanded-for-calc');
      });
    });

    if ($this.hasClass('js-save-spoiler-state')) {
      if (localStorage.getItem('SPOILER_STATE_' + $this.attr('id')) === 'true') {
        $this.addClass('is-expanded');
      }
    }
  });
  setTimeout(function () {
    $(window).trigger('spoilersInit');
  }, 0);

  $(document).on('click', '.js-toggle-spoiler', function toggleClickHandler() {
    var $this = $(this);
    var $spoiler = $this.closest('.js-spoiler');
    var scrollAfter = $this.hasClass('js-scroll-to-closed-spoiler');
    var saveState = $spoiler.hasClass('js-save-spoiler-state');
    $spoiler.toggleClass('is-expanded');
    if (scrollAfter && !$spoiler.hasClass('is-expanded')) {
      $(window).scrollTo($spoiler, { duration: 200, offset: -180 });
    }
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
    if (saveState) {
      localStorage.setItem('SPOILER_STATE_' + $spoiler.attr('id'), $spoiler.hasClass('is-expanded'));
    }
  });

  $('.js-toggle-mobile-spoiler').click(function mobileToggleClickHandler() {
    if (!(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) return;
    $(this).closest('.js-spoiler').toggleClass('is-expanded');
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
  });

  _globals.$DOCUMENT.on('click', '.js-close-download-app', function toggleDownloadApp(e) {
    e.preventDefault();

    var $this = $(this);
    var $parent = $this.closest('.js-download-app__wrapper');

    $parent.toggleClass('collapsed');
  });

  $(document).on('click', '.js-toggle-share', function toggleShareSpoiler(e) {
    e.preventDefault();

    var $toggler = $(this);
    var url = window.location.origin;
    var $spoiler = $toggler.closest('.js-share');
    var $inp = $spoiler.find('.js-share-input');
    var $popup = $spoiler.find('.js-share-body');

    if ($(window).width() > 700) {
      $spoiler.toggleClass('is-expanded');
    } else {
      $.overlayLoader(true, {
        node: $popup,
        hideSelector: '.js-toggle-share',
        fixedElements: '.js-fixed-element'
      });
    }

    $inp.val(url);
  });

  $(document).on('click', '.js-share-btn', function copyShareLink() {
    var $this = $(this);
    var copyText = document.querySelector('.js-share-input');

    copyText.select();
    document.execCommand('copy');
    $this.text('Скопировано');
  });
});

},{"./globals.js":4,"./helpers.js":5}],16:[function(require,module,exports){
'use strict';

$('.js-tabs .tabs-nav li a').click(function clickHandler(e) {
  e.preventDefault();

  var $this = $(this);
  var $panel = $($this.attr('href'));

  $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
  $panel.closest('.tabs').find('.tabs-panel').removeClass('is-active').hide();
  $panel.fadeIn().addClass('is-active');
});

$(function () {
  $('.js-tabs').each(function handler() {
    $(this).find('.tabs-nav li:first a').click();
  });
});

$('.js-toggle-map-view').click(function handler(e) {
  e.preventDefault();

  var $this = $(this);

  $this.addClass('is-active').siblings().removeClass('is-active');
  $($this.attr('href')).stop(true, true).fadeIn();
  $($this.siblings().attr('href')).stop(true, true).hide();

  if ($this.attr('href') === '#map-view') {
    $('.map-section__mobile-content-link').show();
  } else {
    $('.map-section__mobile-content-link').hide();
  }
});

},{}],17:[function(require,module,exports){
'use strict';

$(function () {
  $(document).on('click', '.js-play-video', function replaceVideo(e) {
    e.preventDefault();
    var $this = $(this);
    var $videoLink = $this.prop('href');
    var $video = $('<iframe src="' + $videoLink + '" width="100%" height="100%" allow="autoplay; encrypted-media;\n    fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>');
    $this.find('img').replaceWith($video);
    $this.addClass('video-added');
  });

  $(document).on('click', '.js-video-popup-trigger', function replaceVideo(e) {
    e.preventDefault();
    var $this = $(this);
    var $videoLink = $this.prop('href');
    var $popup = $('.js-video-popup');
    var $video = $('<iframe src="' + $videoLink + '" width="1280" height="720" allow="autoplay; encrypted-media;\n      fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>');

    $popup.find('iframe').replaceWith($video);
    $this.addClass('video-added');

    $.overlayLoader(true, {
      node: $popup,
      hideSelector: '.js-close-popup',
      fixedElements: '.js-fixed-element'
    });
  });
});

},{}]},{},[2]);
